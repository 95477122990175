
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CharacterBio from '@/components/character_bio.vue'
import ClaimCharacterModal from '@/components/modals/confirmations/claim_character.vue'
import TeamBio from '@/components/team/bio.vue'
import TeamMemberForm from '@/components/team/member_form.vue'
import { Character } from '@/interfaces/character'
import { TeamCreateResponse, TeamMemberUpdateErrors } from '@/interfaces/responses'
import Team from '@/interfaces/team'
import TeamMember from '@/interfaces/team_member'
import SavageAimMixin from '@/mixins/savage_aim_mixin'
import TeamMemberCreateNewCharacterForm from '@/components/team/membership_new_character_form.vue'

@Component({
  components: {
    CharacterBio,
    TeamBio,
    TeamMemberForm,
    TeamMemberCreateNewCharacterForm,
  },
})
export default class TeamJoin extends SavageAimMixin {
  createFormRunning = false

  createFormUsed = false

  errors: TeamMemberUpdateErrors = {}

  requesting = false

  team!: Team

  @Prop()
  teamId!: string

  teamLoaded = false

  // Values for sending
  get bisListId(): string {
    try {
      return (this.$refs.form as TeamMemberForm).bisListId
    }
    catch (e) {
      return `${this.characterCreateForm.bisList?.id || '-1'}`
    }
  }

  get characters(): Character[] {
    return this.$store.state.characters
  }

  get characterCreateForm(): TeamMemberCreateNewCharacterForm {
    return this.$refs.characterCreateForm as TeamMemberCreateNewCharacterForm
  }

  get characterId(): string {
    try {
      return (this.$refs.form as TeamMemberForm).characterId
    }
    catch (e) {
      return `${this.characterCreateForm.character?.id || '-1'}`
    }
  }

  get teamProxies(): TeamMember[] {
    return this.team.members.filter((tm: TeamMember) => tm.character.proxy)
  }

  get url(): string {
    return `/backend/api/team/join/${this.teamId}/`
  }

  claim(character: Character): void {
    // Open the modal which will handle it all for us
    this.$modal.show(ClaimCharacterModal, { details: character, teamId: this.team.id, code: this.team.invite_code })
  }

  async createCharAndJoin(): Promise<void> {
    this.createFormUsed = true
    this.createFormRunning = true
    const created = await this.characterCreateForm.createCharAndBIS(this.team.tier)
    if (created) await this.join()
    this.createFormRunning = false
  }

  created(): void {
    this.fetchTeam(false)
  }

  async fetchTeam(reload: boolean): Promise<void> {
    // Load the team data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the JSON into a team and save it
        this.team = (await response.json()) as Team
        this.teamLoaded = true
        document.title = `Join ${this.team.name} - Savage Aim`
        if (reload) this.$forceUpdate()
      }
      else if (response.status === 404) {
        // Handle 404s ourselves to go back to the create/join page with a warning
        this.$router.push('/team/', () => {
          Vue.notify({ text: 'Invalid invite code! Please make sure you copied it correctly!', type: 'is-danger' })
        })
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async join(): Promise<void> {
    if (this.requesting) return
    this.errors = {}
    this.requesting = true

    const body = JSON.stringify({ bis_list_id: this.bisListId, character_id: this.characterId })
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
        body,
      })

      if (response.ok) {
        // Redirect back to the new bis list page
        const json = await response.json() as TeamCreateResponse
        this.$store.dispatch('fetchTeams')
        this.$router.push(`/team/${json.id}/`, () => {
          Vue.notify({ text: `Welcome to ${this.team.name}!`, type: 'is-success' })
        })
      }
      else {
        super.handleError(response.status)
        this.errors = (await response.json() as TeamMemberUpdateErrors)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to create BIS List.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
    finally {
      this.requesting = false
    }
  }

  async load(): Promise<void> {
    this.fetchTeam(true)
  }
}
